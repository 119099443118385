// import {FC, useEffect, useState} from 'react'
// import {KTIcon} from '../../../_metronic/helpers'
// import {SizeUnits, dateFormat, encodeString, isNullOrEmpty} from '../../../services/Utils'
// import {BaseRequest} from '../../../services/Request'
// import {CDN_URL, FlipbookGetByID} from '../../../services/main'
// import {toast} from 'react-toastify'
// import 'react-confirm-alert/src/react-confirm-alert.css'
// import {useTranslation} from 'react-i18next'
// import {Link, useParams} from 'react-router-dom'
// import clsx from 'clsx'
// import {PageLink, PageTitle} from '../../../_metronic/layout/core'
// import MonthlyStat from './stats/time'
// import LocationStat from './stats/location'
// import ScanedDevices from './stats/device'
// import ScanedOs from './stats/os'

import {Link, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useTranslation} from 'react-i18next'
import {FC, useEffect, useState} from 'react'
import {BaseRequest} from '../../../services/Request'
import {toast} from 'react-toastify'
import {KTIcon} from '../../../_metronic/helpers'
import {CDN_URL, OrderGet, ProductSambarGet, ProductTatlagaGet} from '../../../services/main'
import clsx from 'clsx'
import {isNullOrEmpty, moneyFormat} from '../../../services/Utils'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import ExportToExcel from './exportToExcel'
import { profile } from 'console'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Нүүр',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Самбар',
    path: '/product-sambar',
    isSeparator: false,
    isActive: false,
  },
]

const OrderMore: FC = () => {
  const {id} = useParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState<any>([])
  const [products, setProducts] = useState<any>([])
  const [tab, setTab] = useState('products')
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)

  useEffect(() => {
    get()
  }, [])

  const get = () => {
    if (!loading) {
      setLoading(true)
    }
    OrderGet(id, BaseRequest, onGetSuccess, onFailed, 'GET')
  }

  const onGetSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setProducts(response.products)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  //   var newName = null
  //   if (!isNullOrEmpty(data.image_link)) {
  //     newName = data.image_link.split('.')
  //   }
  const defaultRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{cell}</span>
  }
  const songolt1RowFormat = (cell: any, row: any) => {
    return row.product_type == 'tatlaga' ? (
      <span style={{fontSize: 11}}>{t(`common:magicnum.ProductTatlagaJin.${cell}`)}</span>
    ) : (
      <span style={{fontSize: 11}}>{t(`common:magicnum.ProductSambarHemjee.${cell}`)}</span>
    )
  }
  const songolt2RowFormat = (cell: any, row: any) => {
    return row.product_type == 'tatlaga' ? (
      <span style={{fontSize: 11}}>{t(`common:magicnum.ProductTatlagaUrt.${cell}`)}</span>
    ) : (
      <span style={{fontSize: 11}}>{t(`common:magicnum.ProductSambarMaterial.${cell}`)}</span>
    )
  }
  const imgRowFormat = (cell: any, row: any) => {
    var newName = row.product_type == 'tatlaga' ? row.timage.split('.') : row.simage.split('.')
    return (
      <div className='text-center'>
        <img
          src={CDN_URL + newName[1] + '-thumb.' + newName[2]}
          height={40}
          width={40}
          style={{borderRadius: '50%', objectFit: 'cover'}}
        />
      </div>
    )
  }
  const priceRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{moneyFormat(cell, "MNT") }</span>
  }
  const totalpriceRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{moneyFormat(cell * row.price, "MNT") }</span>
  }
  

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Барааны зураг',
        dataField: 'simage',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Бараа',
        dataField: 'sname',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Код',
        dataField: 'scode',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Даац, хэмжээ',
        dataField: 'songolt1',
        dataSort: false,
        dataFormat: songolt1RowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Урт, материал',
        dataField: 'songolt2',
        dataSort: false,
        dataFormat: songolt2RowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Барааны төрөл',
        dataField: 'product_type',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Нэгж үнэ',
        dataField: 'price',
        dataSort: false,
        dataFormat: priceRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Захиалсан тоо',
        dataField: 'too',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Нийт үнэ',
        dataField: 'too',
        dataSort: false,
        dataFormat: totalpriceRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }
  console.log(data)
  console.log(products)
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{data?.name}</PageTitle>
      <div className={`card mb-5`}>
        <div style={{overflowX: 'auto'}} className='card-header border-1 w-full'>
          <div className='flex gap-8 col-form-label w-full card-header-flex'>
            <div className='flex flex-wrap gap-16 p-8 infos w-full'>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '14px',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <span style={{fontWeight: 'bold'}}>Захиалгын дугаар:</span>
                  <span> NO-{data?.id}</span>
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Хувиарласан ажилтан:</span>
                  <span> {t(data?.firstname)}</span>
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Төлөв:</span>
                  <span> {t(`common:magicnum.OrderStatus.${data?.status}`)}</span>
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Мөнгөн дүн:</span>
                  <span> {data?.totalprice}</span>
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Харилцагын РД:</span>
                  <span> {data?.rd}</span>
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Гар утасны дугаар:</span>
                  <span> {data?.mobile}</span>
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Ажлын утасны дугаар:</span>
                  <span>{data?.telephone}</span>
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Холбогдох хүн:</span>
                  <span>{data?.holbogdoh_hun}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div style={{overflowX: 'auto'}} className='card-header card-header-stretch'>
          <ul
            className=' nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'products',
                })}
                onClick={() => setTab('products')}
                role='tab'
              >
                Захиалсан бараанууд
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'xls',
                })}
                onClick={() => setTab('xls')}
                role='tab'
              >
                XLS
              </a>
            </li>
          </ul>
        </div>
        <form className='form'>
          <div className='card-body py-6'>
            {tab === 'products' ? (
              <div className='card-body py-3'>
                <DataTable
                  data={products}
                  table={table}
                  PTotal={PTotal}
                  pageChange={onChangePage}
                  PID={PID}
                  loading={loading}
                />
              </div>
            ) : null}
            {tab === 'xls' ? <ExportToExcel data={products} fileName="myDataa" /> : null}
          </div>
        </form>
      </div>
    </>
  )
}

export {OrderMore}
