import React, { Component, useState } from "react";
import { withTranslation } from "react-i18next";
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import {
  setPageTitle,
  DashboardToonMedeelel,
  DashboardByStatus,
} from "../../../services/main";
import {
  DashboardToonMedeelelReq,
  magicnum,
  DashboardByStatusReq,
} from "../../../services/Request";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Table,
  Spinner,
  Input,
  FormGroup,
} from "reactstrap";
import { isNullOrEmpty } from "../../../services/Utils";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const DashboardWrapper= ()=>{
  const intl = useIntl()

  const [loading, setLoading] = useState(false)


  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     t: this.props.t,
  //     loading: false,
  //     t: this.props.t,
  //     apierrormsg: "",
  //     apierror: false,
  //     toonData: null,
  //     years: [],
  //     year: new Date().getFullYear(),
  //     mounth: "",
  //     loadingHoroo: false,
  //     byStatusData: null,
  //   };
  // }

  // componentDidMount() {
  //   const years = [];
  //   for (let i = 2021; i <= this.state.year; i += 1) {
  //     years.push(i);
  //   }
  //   this.setState({ years });
  //   this.getToonMedee();
  //   this.getByStatus();
  //   setPageTitle(this.state.t("Dashboard.Title"));
  // }

  const getToonMedee = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = DashboardToonMedeelelReq;
    // req.filter.service_id = this.state.service_id;
    // req.filter.year = this.state.year;
    // req.filter.mounth = this.state.mounth;
    DashboardToonMedeelel(req, onTopSuccess, onFailed, "POST");
  };

  const onTopSuccess = (response:any) => {
    setLoading(false)
  };

  const onFailed = (error:any) => {
    setLoading(false)
  };

  const getByStatus = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = DashboardByStatusReq;
   
    DashboardByStatus(req, onByStatusSuccess, onFailed, "POST");
  };

  const onByStatusSuccess = (response:any) => {
    setLoading(false)
  };

  // const handleChange = (e:any) => {
  //   const { name, value } = e.target;
  //   this.setState({ [name]: value }, function () {
  //     if (name == "year" || name == "mounth") {
  //       this.getByStatus();
  //     }
  //   });
  // };

  return(
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
        {intl.formatMessage({id: 'MENU.DASHBOARD'})}
      </PageTitle>
      </>
  )
}



export default DashboardWrapper;
