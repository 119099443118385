import {FC, useEffect, useState} from 'react'
import {isNullOrEmpty} from '../../../../services/Utils'
import {toast} from 'react-toastify'
import {
  BagtsListReq,
  magicnum,
  ProductSambarUpdateReq,
  ProductTatlagaUpdateReq,
} from '../../../../services/Request'
import {
  BagtsList,
  ProductSambarAdd,
  ProductSambarUpdate,
  ProductTatlagaAdd,
  ProductTatlagaUpdate,
} from '../../../../services/main'

interface IProps {
  t: any
  item: any
  onAddSuccess: any
  type: any
}
const SambarEdit: FC<IProps> = ({t, item, type, onAddSuccess}) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [image, setImage] = useState('')
  const [torol, setTorol] = useState('')
  const [subtorol, setsubtorol] = useState('')
  const [brand, setBrand] = useState('')
  const [validate, setValidate] = useState<any>([])
  const [subtorolarray, setsubtorolarray] = useState<any>([])

  useEffect(() => {
    setName(item?.name)
    setCode(item?.code)
    setBrand(item?.brand)
    setTorol(item?.torol)
    setsubtorol(item?.subtorol)
  }, [item])

  const add = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var formdata = new FormData()
      formdata.append('image', image)
      var sambar = {
        name: name,
        torol: torol,
        subtorol: subtorol,
        brand: brand,
        bagts: '',
        code: code,
        tolov: item?.tolov,
        price: item?.price,
      }
      formdata.append('sambar', JSON.stringify(sambar))
      ProductSambarAdd(formdata, onUpdateSuccess, onFailed, 'POST', 'file')
    }
  }

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)

      var req = ProductSambarUpdateReq
      req.sambar.name = name
      req.sambar.torol = torol
      req.sambar.brand = brand
      req.sambar.code = code
      req.sambar.subtorol = subtorol
      req.sambar.bagts = ''
      req.sambar.id = item?.id
      ProductSambarUpdate(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const onUpdateSuccess = () => {
    onAddSuccess()
    setLaoding(false)
    return toast.success("амжилттай")

  }
  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }
  const validateFunc = () => {
    var res = {retType: 0}

    // if (isNullOrEmpty(subCategory)) {
    //   res.retType = 1
    //   validate['orgInp'] = true
    // }
    setValidate(validate)
    setCount(count + 1)
    return res
  }
  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }
  

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 modal-width' style={{paddingInline: '50px'}}>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>{type == 'edit' ? 'Бараа засах' : 'Бараа нэмэх'}</h1>
        </div>
        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
            {type == 'edit' ? 'Бараа засах' : 'Бараа нэмэх'}
          </span>
        </div>
        <div>
          <div className='mb-4'>
            <input
              className='form-control form-control-solid w-300px'
              placeholder='Барааны нэр'
              onChange={(e) => {
                setName(e.target.value)
                validateChange('name', e)
              }}
              value={name}
            ></input>
            {validate['name'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
          <div className='mb-4'>
            <input
              className='form-control form-control-solid w-300px'
              placeholder='Код'
              onChange={(e) => {
                setCode(e.target.value)
                validateChange('code', e)
              }}
              value={code}
            ></input>
            {validate['code'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
          {type == 'add' ? (
            <div className='mb-4'>
              <input
                type='file'
                className='form-control form-control-solid w-300px'
                onChange={(e: any) => {
                  setImage(e.target.files?.[0])
                  validateChange('image', e)
                }}
              ></input>
              {validate['image'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
          ) : null}

          <div className='mb-4'>
            <select
              onChange={(e) => {
                setTorol(e.target.value)
              }}
              className='form-select form-select-sm form-select-solid'
              value={torol}
              id=''
            >
              <option value={''}>Ангилал сонгох</option>
              {magicnum.ProductSambarTorol.map((pitem) => {
                return (
                  <option value={pitem}>{t(`common:magicnum.ProductSambarTorol.${pitem}`)}</option>
                )
              })}
            </select>
            {validate['category'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
          <div className='mb-4'>
            <select
              onChange={(e) => {
                setsubtorol(e.target.value)
              }}
              className='form-select form-select-sm form-select-solid'
              value={subtorol}
              id=''
            >
              <option value={''}>Дэд ангилал сонгох</option>
              {magicnum.ProductSambarSubTorol.map((el: any) => {
                return el.torol == torol ? (
                  <option value={torol + '-' + el.id}>
                    {t(`common:magicnum.ProductSambarSubTorol.${torol}-${el.id}`)}
                  </option>
                ) : null
              })}
            </select>
            {validate['salaa'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
          <div className='mb-4'>
            <select
              onChange={(e) => {
                setBrand(e.target.value)
              }}
              className='form-select form-select-sm form-select-solid'
              value={brand}
            >
              <option value={''}>Брэнд сонгох</option>
              {magicnum.ProductBrand.map((pitem) => {
                return <option value={pitem}>{t(`common:magicnum.ProductBrand.${pitem}`)}</option>
              })}
            </select>
            {validate['brand'] ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{t('common:validInsert')}</div>
              </div>
            ) : null}
          </div>
        </div>

        <div className='pt-10' style={{textAlign: 'right'}}>
          {type == 'edit' ? (
            <button
              className='btn btn-primary align-self-center'
              onClick={update}
              disabled={loading}
            >
              {loading ? t('common:loading') : 'Хадгалах'}
            </button>
          ) : null}
          {type == 'add' ? (
            <button className='btn btn-primary align-self-center' onClick={add} disabled={loading}>
              {loading ? t('common:loading') : 'Хадгалах'}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export {SambarEdit}
