import {useState} from 'react'
import config from './config.json'
var debugModeB = config.debug
var cookieMode = config.cookie
var debugMode = debugModeB
if (cookieMode == 'test') {
  debugMode = 'test'
}
const getItem = (key:any) =>
  document.cookie.split('; ').reduce((total, currentCookie) => {
    const item = currentCookie.split('=')
    const storedKey = item[0]
    const storedValue = item[1]
    var decryptedData = storedValue
    return key === storedKey ? decodeURIComponent(decryptedData) : total
  }, '')

const setItem = (key:any, value:any, numberOfDays:any) => {
  const now = new Date()

  // set the time to be now + numberOfDays
  now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000)

  var newValue = value
  if (debugMode === 'test') {
    document.cookie = `${key}=${newValue}; expires=${now.toUTCString()}; domain=localhost; path=/`
  } else {
    document.cookie = `${key}=${newValue}; expires=${now.toUTCString()}; domain=.mkl.mn; path=/`
  }
}
/**
 *
 * @param {String} key The key to store our data to
 * @param {String} defaultValue The default value to return in case the cookie doesn't exist
 */
const useCookie = (key:any, defaultValue:any) => {
  const getCookie = () => getItem(key) || defaultValue
  const [cookie, setCookie] = useState(getCookie())

  const updateCookie = (value: any, numberOfDays: any) => {
    setCookie(value)
    setItem(key, value, numberOfDays)
  }

  const removeCookie = (key:any) => {
    if (getItem(key)) {
      setCookie('')
      var now = new Date()
      now.setMonth(now.getMonth() - 1)
      var newValue = ''
      if (debugMode === 'test') {
        document.cookie = `${key}=${newValue}; expires=${now.toUTCString()}; domain=localhost; path=/`
      } else {
        document.cookie = `${key}=${newValue}; expires=${now.toUTCString()}; domain=.mkl.mn; path=/`
      }
    }
  }

  return [cookie, updateCookie, removeCookie]
}

export default useCookie
