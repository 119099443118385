// import {FC, useEffect, useState} from 'react'
// import {KTIcon} from '../../../_metronic/helpers'
// import {SizeUnits, dateFormat, encodeString, isNullOrEmpty} from '../../../services/Utils'
// import {BaseRequest} from '../../../services/Request'
// import {CDN_URL, FlipbookGetByID} from '../../../services/main'
// import {toast} from 'react-toastify'
// import 'react-confirm-alert/src/react-confirm-alert.css'
// import {useTranslation} from 'react-i18next'
// import {Link, useParams} from 'react-router-dom'
// import clsx from 'clsx'
// import {PageLink, PageTitle} from '../../../_metronic/layout/core'
// import MonthlyStat from './stats/time'
// import LocationStat from './stats/location'
// import ScanedDevices from './stats/device'
// import ScanedOs from './stats/os'

import {Link, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {useTranslation} from 'react-i18next'
import {FC, useEffect, useState} from 'react'
import {BaseRequest} from '../../../../services/Request'
import {toast} from 'react-toastify'
import {KTIcon} from '../../../../_metronic/helpers'
import {CDN_URL, ProductCranGet, ProductSambarGet, ProductTatlagaGet} from '../../../../services/main'
import clsx from 'clsx'
import {isNullOrEmpty} from '../../../../services/Utils'
import TatlagaUneNemeh from '../price/tatlagaPrice'
import TatlagaImage from '../images/tatlagaImages'
import EditorContainer from '../ProductContent'
import SambarUneNemeh from '../price/sambarPrice'
import SambarImage from '../images/sambarImages'
import CranImage from '../images/cranImages'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Cran',
    path: '/product-cran',
    isSeparator: false,
    isActive: false,
  },
]

const CranMore: FC = () => {
  const {id} = useParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState<any>('')
  const [tab, setTab] = useState('info')

  useEffect(() => {
    get()
  }, [])

  const get = () => {
    if (!loading) {
      setLoading(true)
    }
    ProductCranGet(id, BaseRequest, onGetSuccess, onFailed, 'GET')
  }

  const onGetSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  var newName = null
  if (!isNullOrEmpty(data.image_link)) {
    newName = data.image_link.split('.')
  }
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{data?.name}</PageTitle>
      <div className={`card mb-5`}>
        <div style={{overflowX: 'auto'}} className='card-header border-1 w-full'>
          <div className='flex gap-8 col-form-label w-full card-header-flex'>
            <div className='flex flex-wrap gap-16 p-8 infos w-full'>
              <div className='zurag py-8'>
                {isNullOrEmpty(data.image_link) ? (
                  <div
                    className='no-zurag'
                    style={{
                      width: '150px',
                      borderRadius: '10px',
                      backgroundColor: '#ecf0f1',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    No Image
                  </div>
                ) : (
                  <img
                    src={CDN_URL + newName[1] + '.' + newName[2]}
                    style={{borderRadius: '15px'}}
                    width={150}
                    alt=''
                  />
                )}
              </div>
              <div style={{
                display:"flex",
                flexDirection:"column",
                gap:"14px",
                justifyContent:"center"
              }}>
                
                <div>
                  <span style={{fontWeight:"bold"}}>Барааны нэр:</span>
                  <span> {t(data?.name)}</span>
                </div>
                <div>
                  <span style={{fontWeight:"bold"}}>Ангилал:</span>
                  <span> {t(`common:magicnum.ProductCranTorol.${data?.torol}`)}</span>
                </div>
                <div>
                  <span style={{fontWeight:"bold"}}>Брэнд:</span>
                  <span> {t(`common:magicnum.ProductBrand.${data?.torol}`)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div style={{overflowX: 'auto'}} className='card-header card-header-stretch'>
          <ul
            className=' nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'info',
                })}
                onClick={() => setTab('info')}
                role='tab'
              >
                Дэлгэрэнгүй мэдээлэл
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'images',
                })}
                onClick={() => setTab('images')}
                role='tab'
              >
                Барааны зурагнууд
              </a>
            </li>
            
          </ul>
        </div>
        <form className='form'>
          <div className='card-body py-6'>
            {tab === 'images' ? <CranImage prod={data} t={t} /> : null}
            {tab === 'info' ? <EditorContainer prod={data} type="cran" t={t}/> : null}
          </div>
        </form>
      </div>
    </>
  )
}

export {CranMore}
