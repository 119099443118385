export const BaseRequest = {
  lang:
    localStorage.getItem("Lang") === null ? "mn" : localStorage.getItem("Lang"),
};

export const ListBase = {
  ...BaseRequest,
  pid: 1,
  pSize: 100,
};

export const magicnum = {
  Status: {
    Active: 1,
    InActive: 0,
  },
  Mounth: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  UserType: ["admin", "director", "employee"],
  BannerPosition: [1],
  FirstMenu: [1, 2, 3],
  ProductBrand: [1, 2, 3, 4, 5, 6 , 7],
  ProductTatlagaTorol: [1, 2, 3, 4, 5],
  ProductTatlagaSalaa: [1, 2, 3, 4, 5, 6],
  ProductTatlagaTolov: [1, 2, 3, 4],
  ProductTatlagaJin: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
  ],
  ProductTatlagaUrt: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29,
  ],
  ProductSambarTorol: [1, 2, 3, 4,5 ,6,7,8,9,10,11,12,13,14],
  ProductSambarSubTorol: [
    { torol: 1, id: 1 },
    { torol: 1, id: 2 },
    { torol: 1, id: 4 },
    { torol: 1, id: 5 },
    { torol: 1, id: 6 },
    { torol: 1, id: 7 },
    { torol: 1, id: 8 },
    { torol: 1, id: 9 },
    { torol: 1, id: 10 },
    { torol: 1, id: 11 },
    { torol: 1, id: 12 },
    { torol: 1, id: 13 },
    { torol: 1, id: 14 },
    { torol: 1, id: 15 },
    { torol: 1, id: 16 },
    { torol: 1, id: 17 },
    { torol: 1, id: 18 },
    { torol: 1, id: 19 },
    { torol: 1, id: 20 },
    { torol: 1, id: 21 },
    { torol: 1, id: 22 },
    { torol: 1, id: 23 },
    { torol: 1, id: 24 },
    { torol: 2, id: 1 },
    { torol: 2, id: 2 },
    { torol: 2, id: 3 },
    { torol: 2, id: 4 },
    { torol: 2, id: 5 },
    { torol: 2, id: 6 },
    { torol: 2, id: 7 },
    { torol: 3, id: 1 },
    { torol: 3, id: 2 },
    { torol: 3, id: 3 },
    { torol: 3, id: 4 },
    { torol: 3, id: 5 },
    { torol: 4, id: 1 },
    { torol: 4, id: 2 },
    { torol: 4, id: 3 },
    { torol: 4, id: 4 },
    { torol: 4, id: 5 },
    { torol: 4, id: 6 },
  ],
  ProductCranTorol: [1, 2, 3, 4],
  ProductOndriinTorol: [1, 2, 3, 4, 5, 6, 7, 8, 9],

  ProductSambarHemjee: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
  ],
  ProductSambarMaterial: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44,
  ],
  OrderStatus: [1, 2, 3, 4, 5, 6],
  OrderCranStatus: [1, 2]
};

//#region employee
export const AuthReq = {
  ...BaseRequest,
  Username: "",
  Password: "",
  Status: "",
  FirstName: "",
  LastName: "",
  ExpiredDate: "",
  TypeID: "",
  file: "",
};

export const AuthCreateReq = {
  ...BaseRequest,
  UserName: "",
  Password: "",
};

export const ChangePassReq = {
  ...BaseRequest,
  EmployeeID: "",
  newPassword: "",
  OLdPass: "",
  IsAdmin: false,
  realOldPass: "",
};

export const EmployeeChangeInfoReq = {
  ...BaseRequest,
  FirstName: "",
  LastName: "",
  id: "",
};

export const listEmployeeReq = {
  ...ListBase,
  filter: {
    Name: "",
    SoHoID: "",
    HeHoID: "",
    HesegID: "",
    BagID: "",
    Status: "",
  },
};
//#endregion employee

//#region Privilege
export const PrivilegeGroupAddReq = {
  ...BaseRequest,
  mPriv_Group: {},
};

export const PrivilegeGroupMoreReq = {
  ...BaseRequest,
  mPriv_Group: {},
};

export const PrivilegeGroupDeleteReq = {
  ...BaseRequest,
  mPriv_Group: {},
};

export const PrivilegeAddReq = {
  ...BaseRequest,
  mPriv: {},
};

export const PrivilegeDeleteReq = {
  ...BaseRequest,
  mPriv: {},
};

export const PrivilegeUserListReq = {
  ...BaseRequest,
  privNo: "",
};

export const GetEmployeeByIdReq = {
  ...BaseRequest,
  EmployeeID: "",
};

export const PrivilegeMP_GroupUserAddReq = {
  ...BaseRequest,
  userKey: "",
  mPriv_Groups: [],
};

export const AddMPriv_GroupPrivReq = {
  ...BaseRequest,
  privGroupId: "",
  mPrivs: [],
};

export const GetMPriv_GroupPrivReq = {
  ...BaseRequest,
  privGroupId: "",
};

export const ListMPriv_GroupPrivReq = {
  ...BaseRequest,
  MPriv_Group: {},
};
//#endregion

//#region Log
export const LogActionReq = {
  ...ListBase,
  filter: {
    Controller: "",
    Action: "",
    UserID: "",
    BeginDate: "",
    EndDate: "",
  },
};

export const LogErrorReq = {
  ...ListBase,
  filter: {
    Controller: "",
    Error: "",
    UserID: "",
    BeginDate: "",
    EndDate: "",
  },
};
//#endregion

//#region Chat
export const ChatListReq = {
  ...BaseRequest,
  filter: {
    sender_id: "",
  },
};
export const ChatAddReq = {
  ...BaseRequest,
  chat: {
    body: "",
  },
};
//#endregion

//#region Burtgel
export const BurtgelAddReq = {
  ...BaseRequest,
  burtgel: {
    burtgel_no: "",
    rd: "",
    firstname: "",
    lastname: "",
    horoo_id: "",
    haryalal: "",
    shiidverlesen_eseh: 1,
    service_id: "",
    hariutssan: "",
    year: "",
    mounth: "",
  },
};

export const BurtgelUpdateReq = {
  ...BaseRequest,
  burtgel: {
    rd: "",
    firstname: "",
    lastname: "",
    horoo_id: "",
    haryalal: "",
    shiidverlesen_eseh: "",
    hariutssan: "",
    year: "",
    mounth: "",
    id: "",
  },
};

export const BurtgelListReq = {
  ...ListBase,
  filter: {
    rd: "",
    firstname: "",
    lastname: "",
    horoo_id: "",
    haryalal: "",
    shiidverlesen_eseh: "",
    hariutssan: "",
    year: "",
    mounth: "",
  },
};
//#endregion

//#region Dashboard
export const DashboardByUserReq = {
  ...BaseRequest,
  filter: {
    service_id: "",
    year: "",
    mounth: "",
  },
};

export const DashboardToonMedeelelReq = {
  ...BaseRequest,
  filter: {
    service_id: "",
    year: "",
    mounth: "",
  },
};

export const DashboardByHorooReq = {
  ...BaseRequest,
  filter: {
    service_id: "",
    year: "",
    mounth: "",
  },
};

export const DashboardByStatusReq = {
  ...BaseRequest,
  filter: {
    year: "",
    mounth: "",
  },
};
//#endregion

//#region ProductTatlaga
export const ProductTatlagaListReq = {
  ...ListBase,
  filter: {
    torol: "",
    name: "",
    salaa: "",
    brand: "",
    code: "",
    tolov: "",
  },
};

export const ProductTatlagaAddReq = {
  ...BaseRequest,
  tatlaga: {
    torol: "",
    name: "",
    salaa: "",
    code: "",
    brand: "",
    bagts: "",
    tolov: "",
    price: "",
  },
};

export const ProductTatlagaUpdateReq = {
  ...BaseRequest,
  tatlaga: {
    torol: "",
    name: "",
    salaa: "",
    code: "",
    brand: "",
    bagts: "",
    tolov: "",
    price: "",
    id: "",
  },
};

export const ProductTatlagaUpdateTolovReq = {
  ...BaseRequest,
  tatlaga: {
    tolov: "",
    id: "",
  },
};
//#endregion

//#region ProductTatlagaJin
export const ProductTatlagaJinListReq = {
  ...ListBase,
  filter: {
    product_id: "",
    salaa_id: "",
    jin_id: "",
    name: "",
  },
};

export const ProductTatlagaJinAddReq = {
  ...BaseRequest,
  jin: {
    product_id: "",
    salaa_id: "",
    jin_id: "",
    name: "",
  },
};

export const ProductTatlagaJinUpdateReq = {
  ...BaseRequest,
  jin: {
    salaa_id: "",
    jin_id: "",
    name: "",
    id: "",
  },
};
//#endregion

//#region ProductTatlagaPrice
export const ProductTatlagaPriceJinListReq = {
  ...ListBase,
  filter: {
    product_id: "",
  },
};

export const ProductTatlagaPriceListReq = {
  ...ListBase,
  filter: {
    product_id: "",
    salaa_id: "",
    jin_id: "",
  },
};

export const ProductTatlagaPriceAddReq = {
  ...BaseRequest,
  product_id: "",
  jin_id: "",
  urt_id: "",
  price: "",
  tolov: "",
  soigch: "",
};

export const ProductTatlagaPriceDeleteReq = {
  ...BaseRequest,
  product_id: "",
  jin_id: "",
};

export const ProductTatlagaPriceDeleteOneReq = {
  ...BaseRequest,
  id: "",
};

export const ProductTatlagaPriceUpdateReq = {
  ...BaseRequest,
  product_id: "",
  jin_id: "",
  urt_id: "",
  price: "",
  tolov: "",
  soigch: "",
  id: "",
};
//#endregion

//#region ProductImages
export const ProductImagesListReq = {
  ...ListBase,
  filter: {
    product_id: "",
    product_type: "",
  },
};

export const ProductImagesAddReq = {
  ...BaseRequest,
  images: [],
};

export const ProductImagesUpdateReq = {
  ...BaseRequest,
  images: [],
};
//#endregion

//#region Bagts
export const BagtsListReq = {
  ...ListBase,
  filter: {
    name: "",
    tolov: "",
    type: "",
  },
};

export const BagtsAddReq = {
  ...BaseRequest,
  bagts: {
    name: "",
    image_link: "",
    tolov: "",
    type: "",
  },
};

export const BagtsUpdateReq = {
  ...BaseRequest,
  bagts: {
    name: "",
    tolov: "",
    id: "",
    type: "",
  },
};

export const BagtsUpdateTolovReq = {
  ...BaseRequest,
  bagts: {
    tolov: "",
    id: "",
  },
};
//#endregion

//#region ProductSambar
export const ProductSambarListReq = {
  ...ListBase,
  filter: {
    torol: "",
    name: "",
    subtorol: "",
    code: "",
    brand: "",
  },
};

export const ProductSambarAddReq = {
  ...BaseRequest,
  sambar: {
    torol: "",
    name: "",
    subtorol: "",
    code: "",
    brand: "",
    bagts: "",
  },
};

export const ProductSambarUpdateReq = {
  ...BaseRequest,
  sambar: {
    torol: "",
    name: "",
    subtorol: "",
    code: "",
    brand: "",
    bagts: "",
    id: "",
  },
};

export const ProductSambarUpdateTolovReq = {
  ...BaseRequest,
  sambar: {
    tolov: "",
    id: "",
  },
};
//#endregion

//#region ProductSambarPrice
export const ProductSambarPriceHemjeeListReq = {
  ...ListBase,
  filter: {
    torol: "",
  },
};

export const ProductSambarPriceListReq = {
  ...ListBase,
  filter: {
    torol: "",
    hemjee_id: "",
    material_id: "",
  },
};

export const ProductSambarPriceAddReq = {
  ...BaseRequest,
  torol: "",
  material_id: "",
  hemjee_id: "",
  price: "",
};

export const ProductSambarPriceUpdateReq = {
  ...BaseRequest,
  torol: "",
  material_id: "",
  hemjee_id: "",
  price: "",
  id: "",
};

export const ProductSambarPriceDeleteOneReq = {
  ...BaseRequest,
  id: "",
};
//#endregion

//#region Banner
export const BannerListReq = {
  ...ListBase,
  filter: {
    position: "",
    tolov: "",
  },
};

export const BannerAddReq = {
  ...BaseRequest,
  banner: {
    position: "",
    image_link: "",
    tolov: "",
    button_name: "",
    button_link: "",
  },
};

export const BannerUpdateReq = {
  ...BaseRequest,
  banner: {
    position: "",
    image_link: "",
    tolov: "",
    button_name: "",
    button_link: "",
    id: "",
  },
};

export const BannerUpdateTolovReq = {
  ...BaseRequest,
  banner: {
    tolov: "",
    id: "",
  },
};
//#endregion

//#region ProductCran
export const ProductCranListReq = {
  ...ListBase,
  filter: {
    torol: "",
    name: "",
    code:"",
    brand:"",
    subtorol:""
  },
};

export const ProductCranAddReq = {
  ...BaseRequest,
  cran: {
    torol: "",
    name: "",
  },
};

export const ProductCranUpdateReq = {
  ...BaseRequest,
  cran: {
    torol: "",
    name: "",
    brand: "",
    id: "",
  },
};
//#endregion

//#region ProductContent
export const ProductContentAddReq = {
  ...BaseRequest,
  content: {
    content: "",
    type: "",
    relation_id: "",
    id: "",
  },
};

export const ProductContentGetReq = {
  ...BaseRequest,
  content: {
    type: "",
    relation_id: "",
  },
};
//#endregion

//#region ProductSambarMaterialHemjee
export const ProductSambarMaterialHemjeeListReq = {
  ...ListBase,
  filter: {
    sambar_id: "",
    hemjee_id: "",
    material_id: "",
  },
};

export const ProductSambarMaterialHemjeeAddReq = {
  ...BaseRequest,
  sambar_id: "",
  hemjee_id: "",
  material_id: "",
};

export const ProductSambarMaterialHemjeeDeleteReq = {
  ...BaseRequest,
  id: "",
};
//#endregion

//#region Customer
export const CustomerListReq = {
  ...ListBase,
  filter: {
    rd: "",
    status: "",
    name: "",
  },
};

export const CustomerCheckReq = {
  ...ListBase,
  filter: {
    rd: "",
  },
};

export const CustomerAddReq = {
  ...BaseRequest,
  Customer: {
    rd: "",
    password: "",
    name: "",
    email: "",
    mobile: "",
    telephone: "",
    status: "",
    holbogdoh_hun: "",
    alban_tushaal: "",
  },
};

export const CustomerUpdateReq = {
  ...BaseRequest,
  Customer: {
    rd: "",
    name: "",
    email: "",
    mobile: "",
    telephone: "",
    holbogdoh_hun: "",
    alban_tushaal: "",
    status: "",
    id: "",
  },
};

export const CustomerChangePassReq = {
  ...BaseRequest,
  CustomerID: "",
  newPassword: "",
  OLdPass: "",
  IsAdmin: false,
};
//#endregion

//#region Order
export const OrderListReq = {
  ...ListBase,
  filter: {
    rd: "",
    mobile: "",
    status: "",
    orderid: "",
    customer_id: "",
    hariutssan_hun:""
  },
};

export const OrderAddReq = {
  ...BaseRequest,
  customer_id: "",
  total_price: "",
  products: [],
};

export const OrderChangeStatusReq = {
  ...BaseRequest,
  Order: {
    status: "",
    employeeID:"",
    id: "",
  },
};
//#endregion

//#region Page
export const PageListReq = {
  ...ListBase,
  filter: {
    name: "",
  },
};

export const PageAddReq = {
  ...BaseRequest,
  name: "",
};

export const PageUpdateReq = {
  ...BaseRequest,
  name: "",
  id: "",
};
//#endregion

//#region News
export const NewsListReq = {
  ...ListBase,
  filter: {
    name: "",
  },
};

export const NewsAddReq = {
  ...BaseRequest,
  News: {
    name: "",
    image_link: "",
    desc: "",
  },
};

export const NewsUpdateReq = {
  ...BaseRequest,
  News: {
    name: "",
    desc: "",
    id: "",
  },
};
//#endregion

//#region Cran Huselt
export const CranOrderListReq = {
  ...ListBase,
  filter: {
    status: "",
    customer_id: "",
    product_id: "",
  },
};

export const CranOrderAddReq = {
  ...BaseRequest,
  order: {
    type: "",
    daats: "",
    alslal: "",
    orchin: "",
    ondor: "",
    status: "",
    customer_id: "",
    product_id: "",
  },
};

export const CranOrderChangeStatusReq = {
  ...BaseRequest,
  Order: {
    status: "",
    id: "",
  },
};
//#endregion