import {useEffect, useState} from 'react'
import {BaseRequest, ProductImagesListReq} from '../../../../services/Request'
import {
  ProductImagesList,
  CDN_URL,
  ProductImagesAdd,
  ProductImagesDelete,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {KTIcon} from '../../../../_metronic/helpers'
import {moneyFormat} from '../../../../services/Utils'
import {confirmAlert} from 'react-confirm-alert'

const TatlagaImage = ({prod, t}) => {
  const [image, setImage] = useState('')
  const [urt_id, seturt_id] = useState('')
  const [price, setprice] = useState('')
  const [tolov, settolov] = useState('')
  const [validate, setvalidate] = useState([])
  const [loading, setloading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [data, setdata] = useState([])
  const [item, setItem] = useState([])
  const [sizePerPage, setSizePerPage] = useState('')
  const [PID, setPID] = useState('')
  const [PTotal, setPTotal] = useState('')

  useEffect(() => {
    getImages()
  }, [prod])

  const getImages = () => {
    if (loading) {
      setloading(true)
    }
    var req = ProductImagesListReq
    req.filter.product_id = prod.id
    req.filter.product_type = 2
    ProductImagesList(req, onListSuccess, onFailed, 'POST')
  }

  const onListSuccess = (response) => {
    setloading(false)
    setdata(response.data)
  }

  const save = () => {
    setloading(true)
    var formData = new FormData()
    formData.append('imgCollection', image)
    formData.append('product_id', prod.id)
    formData.append('product_type', 2)
    ProductImagesAdd(formData, onSuccess, onFailed, 'POST', 'file')
  }

  const onSuccess = (response) => {
    setloading(false)
    seturt_id('')
    setprice('')
    settolov('')
    getImages()
  }

  const onFailed = (error) => {
    setloading(false)
  }

  const submit = (row, type) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  deleteFunc(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row) => {
    console.log(row)
    ProductImagesDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE') //serverluu huselt ilgeej bn  !!!!!
  }

  const onDeleteSuccess = () => {
    getImages()
    return toast.error(t('common:SuccessDelete'))
  }
  const onChangePage = (pid) => {
    if (pid >= 1) {
      setPID(pid)
      setloading(true)
    }
  }
  const imgRowFormat = (cell, row) => {
    var newName = cell.split('.')
    return (
      <div style={{textAlign: 'center'}}>
        <img
          src={CDN_URL + newName[1] + '.' + newName[2]}
          height={150}
          width={150}
          style={{borderRadius: '50%', objectFit: 'cover'}}
          onError={(e) => (e.target.src = 'img/no-image.png')}
        />
      </div>
    )
  }
  const defaultRowFormat = (cell, row) => {
    return (
      <div style={{textAlign: 'center'}}>
        <span>{cell}</span>
      </div>
    )
  }

  const actionRowFormat = (cell, row) => {
    return (
      <div className='text-center' style={{minWidth: 215}}>
        <div
          title='Delete'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }
  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Барааны зурагнууд',
        dataField: 'file_url',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: '',
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
      <div className='row'>
        <div className='col'>
          <input
            className='form-control form-control-sm form-control-solid'
            type='file'
            onChange={(e) => setImage(e.target.files[0])}
          />
        </div>
        <div className='col'>
          <div onClick={() => save()} className='btn btn-color-primary btn-active-light-primary'>
            Нэмэх
          </div>
        </div>
      </div>
      <div className='row'>
        <DataTable
          data={data}
          table={table}
          PTotal={PTotal}
          pageChange={onChangePage}
          PID={PID}
          loading={loading}
        />
      </div>
    </div>
  )
}

export default TatlagaImage
