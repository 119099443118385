import * as XLSX from 'xlsx'

const ExportToExcel = ({data, fileName}) => {
  const handleExport = () => {
    const customHeaders = [
      'Код',
      'Төрөл',
      'Нэр',
      'Мөнгөн дүн',
    ]

    const newData = data.map((item) => ({
      'Код': item.scode,
      'Төрөл': item.product_type,
      'Нэр': item.sname,
      'Мөнгөн дүн': item.price,
    }))

    const worksheet = XLSX.utils.json_to_sheet(newData, {header: customHeaders})

    // Create a new workbook
    const workbook = XLSX.utils.book_new()

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

    // Create an Excel file and download it
    XLSX.writeFile(workbook, `${fileName}.xlsx`)
  }

  return <button onClick={handleExport}>Export to Excel</button>
}

export default ExportToExcel
