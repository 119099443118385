import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {
  SizeUnits,
  dateFormat,
  decodeString,
  encodeString,
  getUser,
  isNullOrEmpty,
} from '../../../../services/Utils'
import CustomModal from '../../../modules/CustomModal/modal'

import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link, useInRouterContext} from 'react-router-dom'
import ModalImage from 'react-modal-image'
import {Base64} from 'js-base64'
import {useSearchParams} from 'react-router-dom'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {BaseRequest, magicnum, ProductSambarListReq, ProductTatlagaListReq} from '../../../../services/Request'
import {CDN_URL, ProductSambarDelete, ProductSambarList, ProductTatlagaDelete, ProductTatlagaList} from '../../../../services/main'
import { SambarEdit } from './edit'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  uid?: any
}

const SambarList: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [addShowModal, setAddShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [name, setName] = useState('')
  const [category, setCategory] = useState('')
  const [sub_category, setSub_category] = useState('')
  const [status, setStatus] = useState<any>('')
  const [torol, setTorol] = useState('')
  const [code, setCode] = useState('')
  const [brand, setBrand] = useState('')
  const [salaa, setSalaa] = useState('')

  useEffect(() => {
    list()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = ProductSambarListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.name = name
    req.filter.torol = torol
    req.filter.code = code
    req.filter.brand = brand
    // req.filter.salaa = salaa
    ProductSambarList(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{cell}</span>
  }
  const subCategoryRowFormat = (cell: any, row: any) => {
    return (
      <span className='flex justify-center'>
        {t(`common:magicnum.ProductSambarSubTorol.${cell}`)}
      </span>
    )
  }
  const categoryRowFormat = (cell: any, row: any) => {
    return (
      <span className='flex justify-center'>
        {t(`common:magicnum.ProductSambarTorol.${cell}`)}
      </span>
    )
  }
  const brandRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{t(`common:magicnum.ProductBrand.${cell}`)}</span>
  }
  const imgRowFormat = (cell: any, row: any) => {
    var newName = cell.split('.')

    return (
      <div className='flex flex-col items-center'>
        <img
          src={CDN_URL + newName[1] + '-thumb.' + newName[2]}
          height={40}
          width={40}
          style={{objectFit: 'cover'}}
          // onError={(e) => (e.target.src = require("../../img/no-image.png"))}
        />
      </div>
    )
  }
  const linkRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex flex-col gap-2'>
        <a href={`/product-sambar/${row.id}`}>{cell}</a>
      </div>
    )
  }
  const statusRowFormat = (cell: any, row: any) => {
    var cl = 'btn-success'
    if (cell == 1) {
      cl = 'btn-success'
    } else if (cell == 2) {
      cl = 'btn-info'
    } else if (cell == 3) {
      cl = 'btn-warning'
    } else if (cell == 4) {
      cl = 'btn-danger'
    }

    let edit = (
      <div style={{textAlign: 'center'}}>
        <span
          style={{
            padding: '3px 10px',
            fontSize: 10,
            borderRadius: '5px',
            cursor: 'pointer',
          }}
          className={`${cl}`}
          onClick={() => {
            submit(row, 'confirm')
          }}
        >
          {t(`common:magicnum.ProductTatlagaTolov.${cell}`)}
        </span>
      </div>
    )
    return row.torol == 5 ? edit : null
  }
  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='justify-center flex' style={{minWidth: '229px'}}>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            updateShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }
  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  deleteFunc(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }
  const deleteFunc = (row: any) => {
    ProductSambarDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = () => {
    list()
    return toast.success(t('common:successDelete'))
  }

  const clearFilter = () => {
    setName('')
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  const search = () => {
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Барааны зураг',
        dataField: 'image_link',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Барааны нэр',
        dataField: 'name',
        dataSort: false,
        dataFormat: linkRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Код',
        dataField: 'code',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Ангилал',
        dataField: 'torol',
        dataSort: false,
        dataFormat: categoryRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Дэд ангилал',
        dataField: 'subtorol',
        dataSort: false,
        dataFormat: subCategoryRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Брэнд',
        dataField: 'brand',
        dataSort: false,
        dataFormat: brandRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Төлөв',
        dataField: 'tolov',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'action',
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }
  const closeModal = () => {
    updateShowModal(false)
    setAddShowModal(false)
    list()
  }
  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <SambarEdit t={t} item={item} type='edit' onAddSuccess={closeModal} />
        </CustomModal>
      ) : null}

      {addShowModal ? (
        <CustomModal
          visible={addShowModal}
          closeModal={() => setAddShowModal(false)}
          className='VideoModal'
        >
          <SambarEdit t={t} item={item} type='add' onAddSuccess={closeModal} />
        </CustomModal>
      ) : null}

      {props.type == 'component' ? null : (
        <>
          <PageTitle breadcrumbs={dashboardBreadCrumbs}>Самбар</PageTitle>

          <div className={`card mb-5`}>
            <div className='card-header border-1' style={{height: '60px'}}>
              <div
                onClick={() => setShowFilter(!showFilter)}
                className=' cursor-pointer filter flex items-center justify-center gap-0'
              >
                <KTIcon iconName='filter' className='fs-3' />
                <span className='text'>{t('common:filter')}</span>
              </div>
              <div className='card-toolbar'>
                <a onClick={() => search()} className='btn btn-sm btn-light-primary'>
                  {t('common:search')}
                </a>
              </div>
            </div>
            {showFilter ? (
              <div className='card-body'>
                <div className='row flex items-center filter-wrap'>
                  <div className='col'>
                    <input
                      className='form-control form-control-sm form-control-solid'
                      id='name'
                      type='text'
                      onChange={(e) => setName(e.target.value)}
                      placeholder='Барааны нэр'
                      value={name}
                    />
                  </div>
                  <div className='col'>
                    <input
                      className='form-control form-control-sm form-control-solid'
                      id='name'
                      type='text'
                      onChange={(e) => setCode(e.target.value)}
                      placeholder='Код'
                      value={code}
                    />
                  </div>
                  <div className='col'>
                    <select
                      onChange={(e) => {
                        setTorol(e.target.value)
                      }}
                      className='form-select form-select-sm form-select-solid'
                      name='category'
                      value={torol}
                    >
                      <option value=''>{t('common:select')}</option>
                      {magicnum.ProductTatlagaTorol.map((pitem) => {
                        return (
                          <option value={pitem}>
                            {t(`common:magicnum.ProductTatlagaTorol.${pitem}`)}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className='col'>
                    <select
                      onChange={(e) => {
                        setSalaa(e.target.value)
                      }}
                      className='form-select form-select-sm form-select-solid'
                      name='sub_category'
                      value={salaa}
                    >
                      <option value=''>{t('common:select')}</option>
                      {magicnum.ProductTatlagaSalaa.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {t(`common:magicnum.ProductTatlagaSalaa.${item}`)}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className='col'>
                    <select
                      onChange={(e) => {
                        setBrand(e.target.value)
                      }}
                      className='form-select form-select-sm form-select-solid'
                      value={brand}
                    >
                      <option value=''>{t('common:select')}</option>
                      {magicnum.ProductBrand.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {t(`common:magicnum.ProductBrand.${item}`)}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className='col'>
                    <a
                      onClick={() => clearFilter()}
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    >
                      {t('common:USERS.clear')}
                    </a>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}

      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Самбар</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Total {PTotal} бараа</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <div
              className='btn btn-sm btn-light-primary'
              onClick={() => {
                setItem(undefined)
                setAddShowModal(true)
              }}
            >
              <KTIcon iconName='plus' className='fs-3' />
              Бараа нэмэх
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {SambarList}
