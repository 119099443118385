import {useEffect, useState} from 'react'
import {
  magicnum,
  ProductTatlagaPriceUpdateReq,
  ProductTatlagaPriceAddReq,
  ProductTatlagaPriceListReq,
  ProductTatlagaPriceDeleteOneReq,
} from '../../../../services/Request'
import {
  ProductTatlagaPriceUpdate,
  ProductTatlagaPriceAdd,
  ProductTatlagaPriceList,
  ProductTatlagaPriceDeleteOne,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {KTIcon} from '../../../../_metronic/helpers'
import {moneyFormat} from '../../../../services/Utils'
import {confirmAlert} from 'react-confirm-alert'
import { useParams } from 'react-router-dom'

const TatlagaUneNemeh = ({prod, t}) => {
  const [salaa_id, setSalaa_id] = useState('')
  const [jin_id, setjin_id] = useState('')
  const [urt_id, seturt_id] = useState('')
  const [price, setprice] = useState('')
  const [tolov, settolov] = useState('')
  const [validate, setvalidate] = useState([])
  const [loading, setloading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [data, setdata] = useState([])
  const [updating, setUpdating] = useState(false)
  const [sizePerPage, setSizePerPage] = useState('')
  const [PID, setPID] = useState('')
  const [PTotal, setPTotal] = useState('')
  const [rowid, setrowid] = useState('')

  useEffect(() => {
    getPrice()
  }, [prod, jin_id])

  const getPrice = () => {
    if (loading) {
      setloading(true)
    }
    var req = ProductTatlagaPriceListReq
    req.filter.product_id = prod.id
    req.filter.jin_id = jin_id
    ProductTatlagaPriceList(req, onListSuccess, onFailed, 'POST')
  }

  const onListSuccess = (response) => {
    setloading(false)
    setdata(response.data)
  }

  // const handleChange = (e) => {
  //   const {name, value} = e.target
  //   this.setState({[name]: value}, function () {
  //     if (name == 'jin_id') {
  //       this.getPrice()
  //       this.setState({
  //         urt_id: '',
  //         price: '',
  //         tolov: '',
  //       })
  //     }
  //   })
  // }

  const save = () => {
    // var res = validateFunc()
    // if (res.retType === 0) {
    setloading(true)
    var req = ProductTatlagaPriceAddReq
    req.product_id = prod.id
    req.jin_id = parseInt(jin_id)
    req.urt_id = parseInt(urt_id)
    req.price = price
    req.tolov = parseInt(tolov)
    ProductTatlagaPriceAdd(req, onSuccess, onFailed, 'POST')
    // }
  }

  const Update = () => {
      var req = ProductTatlagaPriceAddReq
      req.product_id = prod.id
      req.jin_id = parseInt(jin_id)
      req.urt_id = parseInt(urt_id)
      req.price = price
      req.tolov = parseInt(tolov)
      req.id = parseInt(rowid)
      ProductTatlagaPriceUpdate(req, onSuccess, onFailed, 'PUT')
  }

  const onSuccess = (response) => {
    setloading(false)
    seturt_id('')
    setprice('')
    settolov('')
    getPrice()
  }

  const onFailed = (error) => {
    setloading(false)
  }

  const setValidate = (id, e) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    const {validate} = this.state
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    this.setState({validate})
  }

  const validateFunc = () => {
    var res = {retType: 0}

    // if (urt_id == '') {
    //   res.retType = 1
    //   validate['urt_id'] = true
    // }
    // if (price == '') {
    //   res.retType = 1
    //   validate['price'] = true
    // }
    // if (tolov == '') {
    //   res.retType = 1
    //   validate['tolov'] = true
    // }
    setValidate(validate)
    return res
  }
  const submit = (row, type) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  deleteFunc(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row) => {
    var req = ProductTatlagaPriceDeleteOneReq
    req.id = row.id
    ProductTatlagaPriceDeleteOne(req, onDeleteSuccess, onFailed, 'POST') //serverluu huselt ilgeej bn  !!!!!
  }

  const onDeleteSuccess = () => {
    getPrice()
    return toast.error(t('common:SuccessDelete'))
  }
  const onChangePage = (pid) => {
    if (pid >= 1) {
      setPID(pid)
      setloading(true)
    }
  }
  const urtRowFormat = (cell, row) => {
    return (
      <div style={{textAlign: 'center'}}>
        <span>{t(`common:magicnum.ProductTatlagaUrt.${cell}`)}</span>
      </div>
    )
  }
  const daatsRowFormat = (cell, row) => {
    return (
      <div style={{textAlign: 'center'}}>
        <span>{t(`common:magicnum.ProductTatlagaJin.${cell}`)}</span>
      </div>
    )
  }
  const defaultRowFormat = (cell, row) => {
    return (
      <div style={{textAlign: 'center'}}>
        <span>{cell}</span>
      </div>
    )
  }
  const statusRowFormat = (cell, row) => {
    return (
      <div style={{textAlign: 'center'}}>
        <span>{t(`common:magicnum.ProductTatlagaTolov.${cell}`)}</span>
      </div>
    )
  }
  const priceRowFormat = (cell, row) => {
    return (
      <div style={{textAlign: 'center'}}>
        <span>{moneyFormat(cell, 'MNT')}</span>
      </div>
    )
  }
  const actionRowFormat = (cell, row) => {
    return (
      <div className='text-center' style={{minWidth: 215}}>
        <div
          title='Edit'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            editItem(row)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          title='Delete'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }
  const editItem = (row) => {
    setjin_id(row.jin_id)
    seturt_id(row.urt_id)
    settolov(row.tolov)
    setprice(row.price)
    setUpdating(true)
    setrowid(row.id)
  }
  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Даацын хэмжээ',
        dataField: 'jin_id',
        dataSort: false,
        dataFormat: daatsRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Уртын хэмжээ',
        dataField: 'urt_id',
        dataSort: false,
        dataFormat: urtRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Төлөв',
        dataField: 'tolov',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Үнэ',
        dataField: 'price',
        dataSort: false,
        dataFormat: priceRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
      <div className='row'>
        <div className='col'>
          <select
            onChange={(e) => {
              setjin_id(e.target.value)
            }}
            className='form-select form-select-sm form-select-solid'
            name='provider'
            value={jin_id}
            id=''
          >
            <option value=''>Даацын хэмжээ</option>
            {magicnum.ProductTatlagaJin.map((el) => {
              return <option value={el}>{t(`common:magicnum.ProductTatlagaJin.${el}`)}</option>
            })}
          </select>
        </div>
        <div className='col'>
          <select
            onChange={(e) => {
              seturt_id(e.target.value)
            }}
            className='form-select form-select-sm form-select-solid'
            name='provider'
            value={urt_id}
            id=''
          >
            <option value=''>Уртын хэмжээ</option>
            {magicnum.ProductTatlagaUrt.map((el) => {
              return <option value={el}>{t(`common:magicnum.ProductTatlagaUrt.${el}`)}</option>
            })}
          </select>
        </div>
        <div className='col'>
          <select
            onChange={(e) => {
              settolov(e.target.value)
            }}
            className='form-select form-select-sm form-select-solid'
            name='provider'
            value={tolov}
            id=''
          >
            <option value=''>Төлөв</option>
            {magicnum.ProductTatlagaTolov.map((el) => {
              return <option value={el}>{t(`common:magicnum.ProductTatlagaTolov.${el}`)}</option>
            })}
          </select>
        </div>
        <div className='col'>
          <input
            className='form-control form-control-sm form-control-solid'
            id='email'
            type='text'
            onChange={(e) => setprice(e.target.value)}
            placeholder='Үнэ'
            value={price}
          />
        </div>
        <div className='col'>
          <div
            onClick={() => (updating ? Update() : save())}
            className='btn btn-color-primary btn-active-light-primary'
          >
            Хадгалах
          </div>
        </div>
      </div>
      <div className='row'>
        <DataTable
          data={data}
          table={table}
          PTotal={PTotal}
          pageChange={onChangePage}
          PID={PID}
          loading={loading}
        />
      </div>
    </div>
  )
}

export default TatlagaUneNemeh
