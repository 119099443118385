import React, {FC, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import CustomModal from '../../modules/CustomModal/modal'
import {dateTimeFormat, isNullOrEmpty} from '../../../services/Utils'
import {
  BaseRequest,
  ChangePassReq,
  GetEmployeeByIdReq,
  ListBase,
  PrivilegeMP_GroupUserAddReq,
  magicnum,
} from '../../../services/Request'
import {
  AddMPriv_GroupUser,
  CDN_URL,
  ChangePass,
  ListMPriv_GroupsForUser,
  PrivilegeGroupList,
  UpdateMPriv_GroupUser,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {useParams, useSearchParams} from 'react-router-dom'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Employee',
    path: '/employee',
    isSeparator: false,
    isActive: false,
  },
]

const EmployeeMore: FC = () => {
  const {id} = useParams()
  const [searchParams] = useSearchParams()
  const title = searchParams.get('title')
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [IsAdd, setIsAdd] = useState(true)
  const [Password, setPassword] = useState('')
  const [PrivGroups, setPrivGroups] = useState([])
  const [currentPriv_Groups, setCurrentPriv_Groups] = useState<any>([])
  const [count, setCount] = useState(1)
  const [group, setgroup] = useState<any>([])

  useEffect(() => {
    const groupp = currentPriv_Groups.map((i: any) => ({ privGroupId: i.PrivGroupID }));
    setgroup(groupp);
  }, [currentPriv_Groups]);

  useEffect(() => {
    getPrivGroupsList()
    GroupsForUser()
  }, [id])

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  const save = () => {
    if (!isNullOrEmpty(Password) && !isNullOrEmpty(id)) {
      var req = ChangePassReq
      req.EmployeeID = id === undefined ? '' : id
      req.newPassword = Password
      req.IsAdmin = true
      ChangePass(req, onSuccess, onFailed, 'POST')
    }
  }

  const onSuccess = (response: any) => {
    return toast.info(t('common:employee.changePass'))
  }

  const getPrivGroupsList = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = ListBase
    PrivilegeGroupList(req, onPrivGroupListSuccess, onFailed, 'GET')
  }

  const onPrivGroupListSuccess = (response: any) => {
    setLoading(false)
    setPrivGroups(response.mPriv_GroupForLists)
  }

  const GroupsForUser = () => {
    var request = GetEmployeeByIdReq
    ListMPriv_GroupsForUser(id, request, GroupsForUserSuccess, onFailed, 'GET')
  }

  const GroupsForUserSuccess = (response: any) => {
    if (response.mPriv_GroupUser.length != 0) {
      setCurrentPriv_Groups(response.mPriv_GroupUser)
      setIsAdd(false)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const checkPrivGroup = (privv: any, e: any) => {
    if (e.target.checked == true) {
      currentPriv_Groups.push(privv)
    } else {
      var a = 0

      currentPriv_Groups.map((i: any, index: number) => {
        if (privv.PrivGroupID == i.PrivGroupID) {
          a = index
        }
      })
      currentPriv_Groups.splice(a, 1)
    }
    const groupp = currentPriv_Groups.map((i: any) => ({ privGroupId: i.PrivGroupID }));
    setgroup(groupp);
    setCurrentPriv_Groups(currentPriv_Groups)
    setCount(count + 1)
  }

  const CurrentChecked = (currentGroup: any, i: number) => {
    var checker = false
    if (currentGroup != undefined) {
      currentGroup.map((j: any, index: number) => {
        if (j.PrivGroupID == i) {
          checker = true
        }
      })
    }
    return checker
  }
  const savePrivMpGroup_User = () => {
    var request = PrivilegeMP_GroupUserAddReq
    request.userKey = id !== undefined ? id : ''

    request.mPriv_Groups = group

    //console.log(request)
    if (IsAdd) {
      AddMPriv_GroupUser(request, EditedPrivMpSuccess, onFailed, 'POST')
    } else {
      UpdateMPriv_GroupUser(request, EditedPrivMpSuccess, onFailed, 'PUT')
    }
  }

  const EditedPrivMpSuccess = (response: any) => {
    return toast.info(t('common:SuccessEdit'))
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{title}</PageTitle>
      <div className='card'>
        <div style={{visibility: 'hidden'}}>{count}</div>
        <div className='card-body pt-9 pb-0'>
          <div className='row'>
            <div className='col-lg-4 fv-row mb-8'>
              <input
                className='form-control form-control-solid'
                placeholder='New Password'
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
                value={Password}
              ></input>
            </div>
            <div className='col-lg-4 fv-row mb-8'>
              <button
                className='btn btn-primary align-self-center'
                onClick={save}
                disabled={loading}
              >
                {loading ? t('common:loading') : t('common:changePassword')}
              </button>
            </div>
          </div>
          <div className='separator mt-8 d-flex flex-center mb-8'>
            <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>PRIV GROUP</span>
          </div>
          {PrivGroups.map((priv: any, index) => (
            <label
              key={index}
              className='form-check form-switch form-check-custom form-check-solid pt-3'
            >
              <input
                className='form-check-input cursor-pointer'
                checked={CurrentChecked(currentPriv_Groups, priv.PrivGroupID)}
                onChange={(e) => checkPrivGroup(priv, e)}
                type='checkbox'
              />
              <span className='form-check-label fw-bold text-gray-400'>{priv?.PrivGroupName}</span>
            </label>
          ))}
          <br />
          <button
            className='btn btn-primary align-self-center'
            onClick={savePrivMpGroup_User}
            disabled={loading}
          >
            {loading ? t('common:loading') : t('common:save')}
          </button>
          <br />
          <br />
          <br />
        </div>
      </div>
    </>
  )
}

export {EmployeeMore}
