import {useEffect, useState} from 'react'
import {
  magicnum,
  ProductTatlagaPriceUpdateReq,
  ProductTatlagaPriceAddReq,
  ProductTatlagaPriceListReq,
  ProductTatlagaPriceDeleteOneReq,
  ProductSambarPriceListReq,
} from '../../../../services/Request'
import {
  ProductTatlagaPriceUpdate,
  ProductTatlagaPriceAdd,
  ProductTatlagaPriceList,
  ProductTatlagaPriceDeleteOne,
  ProductSambarPriceList,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {KTIcon} from '../../../../_metronic/helpers'
import {moneyFormat} from '../../../../services/Utils'
import {confirmAlert} from 'react-confirm-alert'

const SambarUneNemeh = ({prod, t}) => {
  const [size, setSize] = useState('')
  const [material, setMaterial] = useState('')
  const [loading, setloading] = useState(false)
  const [data, setdata] = useState([])
  const [sizePerPage, setSizePerPage] = useState('')
  const [PID, setPID] = useState('')
  const [PTotal, setPTotal] = useState('')

  useEffect(() => {
    getPrice()
  }, [prod, size, material])

  const getPrice = () => {
    if (loading) {
      setloading(true)
    }
    var req = ProductSambarPriceListReq
    req.filter.hemjee_id = size
    req.filter.material_id = material
    ProductSambarPriceList(req, onListSuccess, onFailed, 'POST')
  }

  const onListSuccess = (response) => {
    setloading(false)
    setdata(response.data)
  }

  const onFailed = (error) => {
    setloading(false)
  }

  const submit = (row, type) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  deleteFunc(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row) => {
    var req = ProductTatlagaPriceDeleteOneReq
    req.id = row.id
    ProductTatlagaPriceDeleteOne(req, onDeleteSuccess, onFailed, 'POST') //serverluu huselt ilgeej bn  !!!!!
  }

  const onDeleteSuccess = () => {
    getPrice()
    return toast.error(t('common:SuccessDelete'))
  }
  const onChangePage = (pid) => {
    if (pid >= 1) {
      setPID(pid)
      setloading(true)
    }
  }
  const sizeRowFormat = (cell, row) => {
    return (
      <div style={{textAlign: 'center'}}>
        <span>{t(`common:magicnum.ProductSambarHemjee.${cell}`)}</span>
      </div>
    )
  }
  const materialRowFormat = (cell, row) => {
    return (
      <div style={{textAlign: 'center'}}>
        <span>{t(`common:magicnum.ProductSambarMaterial.${cell}`)}</span>
      </div>
    )
  }
  const defaultRowFormat = (cell, row) => {
    return (
      <div style={{textAlign: 'center'}}>
        <span>{cell}</span>
      </div>
    )
  }
 
  const priceRowFormat = (cell, row) => {
    return (
      <div style={{textAlign: 'center'}}>
        <span>{moneyFormat(cell, 'MNT')}</span>
      </div>
    )
  }
  const actionRowFormat = (cell, row) => {
    return (
      <div className='text-center' style={{minWidth: 215}}>
        
        <div
          title='Delete'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Хэмжээ',
        dataField: 'hemjee_id',
        dataSort: false,
        dataFormat: sizeRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Материал',
        dataField: 'material_id',
        dataSort: false,
        dataFormat: materialRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Үнэ',
        dataField: 'price',
        dataSort: false,
        dataFormat: priceRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
      <div className='row'>
        <div className='col'>
          <select
            onChange={(e) => {
              setSize(e.target.value)
            }}
            className='form-select form-select-sm form-select-solid'
            name='provider'
            value={size}
            id=''
          >
            <option value=''>Хэмжээ</option>
            {magicnum.ProductSambarHemjee.map((el) => {
              return <option value={el}>{t(`common:magicnum.ProductSambarHemjee.${el}`)}</option>
            })}
          </select>
        </div>
        <div className='col'>
          <select
            onChange={(e) => {
              setMaterial(e.target.value)
            }}
            className='form-select form-select-sm form-select-solid'
            name='provider'
            value={material}
            id=''
          >
            <option value=''>Материал</option>
            {magicnum.ProductSambarMaterial.map((el) => {
              return <option value={el}>{t(`common:magicnum.ProductSambarMaterial.${el}`)}</option>
            })}
          </select>
        </div>
        
        
      </div>
      <div className='row'>
        <DataTable
          data={data}
          table={table}
          PTotal={PTotal}
          pageChange={onChangePage}
          PID={PID}
          loading={loading}
        />
      </div>
    </div>
  )
}

export default SambarUneNemeh
