import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {
  SizeUnits,
  dateFormat,
  decodeString,
  encodeString,
  getUser,
  isNullOrEmpty,
} from '../../../services/Utils'
import CustomModal from '../../modules/CustomModal/modal'

import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link, useInRouterContext} from 'react-router-dom'
import ModalImage from 'react-modal-image'
import {Base64} from 'js-base64'
import {useSearchParams} from 'react-router-dom'
import {
  BaseRequest,
  CranOrderListReq,
  magicnum,
  ProductSambarListReq,
  ProductTatlagaListReq,
} from '../../../services/Request'
import {
  CDN_URL,
  CranOrderList,
  ProductSambarDelete,
  ProductSambarList,
  ProductTatlagaDelete,
  ProductTatlagaList,
} from '../../../services/main'
import {SambarEdit} from '../products/sambar/edit'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  uid?: any
}

const CranOrderLists: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [addShowModal, setAddShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [customer_id, setcustomer_id] = useState('')
  const [status, setStatus] = useState<any>('')

  useEffect(() => {
    list()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = CranOrderListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.status = status
    req.filter.customer_id = customer_id
    CranOrderList(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onFailed = (error: any) => {
    setLoading(false)
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{cell}</span>
  }

  const statusRowFormat = (cell: any, row: any) => {
    return (
      <span className='flex justify-center'>{t(`common:magicnum.OrderCranStatus.${cell}`)}</span>
    )
  }

  const clearFilter = () => {
    setStatus('')
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  const search = () => {
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Харилцагчийн РД',
        dataField: 'rd',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Харилцагчийн нэр',
        dataField: 'name',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Краны төрөл',
        dataField: 'type',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Даац',
        dataField: 'daats',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Алслал (дугуй хоорондын зай)',
        dataField: 'alslal',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Өргөх өндөр',
        dataField: 'ondor',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Ашиглах орчин, нөхцөл',
        dataField: 'orchin',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Төлөв',
        dataField: 'status',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }
  const closeModal = () => {
    updateShowModal(false)
    setAddShowModal(false)
    list()
  }
  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <SambarEdit t={t} item={item} type='edit' onAddSuccess={closeModal} />
        </CustomModal>
      ) : null}

      {addShowModal ? (
        <CustomModal
          visible={addShowModal}
          closeModal={() => setAddShowModal(false)}
          className='VideoModal'
        >
          <SambarEdit t={t} item={item} type='add' onAddSuccess={closeModal} />
        </CustomModal>
      ) : null}

      {props.type == 'component' ? null : (
        <>
          <PageTitle breadcrumbs={dashboardBreadCrumbs}>Кран</PageTitle>

          <div className={`card mb-5`}>
            <div className='card-header border-1' style={{height: '60px'}}>
              <div
                onClick={() => setShowFilter(!showFilter)}
                className=' cursor-pointer filter flex items-center justify-center gap-0'
              >
                <KTIcon iconName='filter' className='fs-3' />
                <span className='text'>{t('common:filter')}</span>
              </div>
              <div className='card-toolbar'>
                <a onClick={() => search()} className='btn btn-sm btn-light-primary'>
                  {t('common:search')}
                </a>
              </div>
            </div>
            {showFilter ? (
              <div className='card-body'>
                <div className='row flex items-center filter-wrap'>
                  
                  <div className='col'>
                    <select
                      onChange={(e) => {
                        setStatus(e.target.value)
                      }}
                      className='form-select form-select-sm form-select-solid'
                      name='category'
                      value={status}
                    >
                      <option value=''>{t('common:select')}</option>
                      {magicnum.OrderCranStatus.map((pitem: any) => {
                        return (
                          <option value={pitem}>
                            {t(`common:magicnum.OrderCranStatus.${pitem}`)}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  
                  <div className='col'>
                    <a
                      onClick={() => clearFilter()}
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    >
                      {t('common:USERS.clear')}
                    </a>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}

      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Кран үнийн санал</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Total {PTotal} санал</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <div
              className='btn btn-sm btn-light-primary'
              onClick={() => {
                setItem(undefined)
                setAddShowModal(true)
              }}
            >
              <KTIcon iconName='plus' className='fs-3' />
              Бараа нэмэх
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {CranOrderLists}
