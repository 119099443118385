import React, { Component, useEffect, useState } from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Row, Button, FormGroup } from "reactstrap";
import {
  ProductContentAddReq,
  ProductContentGetReq,
} from "../../../services/Request";
import {
  ProductContentAdd,
  ProductContentGet,
  ProductContentUpdate,
} from "../../../services/main";
import { isNullOrEmpty } from "../../../services/Utils"
import { toast } from "react-toastify";

function uploadImageCallBack(file) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.imgur.com/3/image");
    xhr.setRequestHeader("Authorization", "Client-ID 30119699f8544d2");
    const data = new FormData();
    data.append("image", file);
    xhr.send(data);
    xhr.addEventListener("load", () => {
      const response = JSON.parse(xhr.responseText);
      console.log(response);
      resolve(response);
    });
    xhr.addEventListener("error", () => {
      const error = JSON.parse(xhr.responseText);
      console.log(error);
      reject(error);
    });
  });
}

const EditorContainer =({prod, t, type})=> {
  const [editorState , seteditorState] = useState('')
  const [updateOrAdd , setupdateOrAdd] = useState('add')
  const [loading , setLoading] = useState(false)

  console.log(updateOrAdd)
  console.log(prod)

  useEffect(()=>{
    getContent()
  },[prod])

  const getContent = () => {
    var req = ProductContentGetReq;
    req.content.relation_id = prod.id;
    req.content.type = type;
    ProductContentGet(req, onSuccess, onFailed, "POST");
  };

  const onSuccess = (response) => {
    setLoading(false)
    if (!isNullOrEmpty(response.data)) {
      var editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(response.data.content))
      );
      seteditorState(editorState)
      setupdateOrAdd(response.data.id)
    }
  };

  const onFailed = (error) => {
    setLoading(false)
  };

  const save = () => {
    setLoading(true)
    var aa = convertToRaw(editorState.getCurrentContent());
    var req = ProductContentAddReq;
    req.content.relation_id = prod.id;
    req.content.type = type;
    req.content.content = JSON.stringify(aa);
    if (updateOrAdd == "add") {
      ProductContentAdd(req, onSaveSuccess, onFailed, "POST");
    } else {
      req.content.id = updateOrAdd;
      ProductContentUpdate(req, onSaveSuccess, onFailed, "PUT");
    }
  };

  const onSaveSuccess = (response) => {
    setLoading(false)
    return toast.success(t("common:SuccessEdit"));
  };

 

  const onEditorStateChange = (editorState) => {
    //console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    seteditorState(editorState)
  };

  
    return (
      <div className="editor">
        <Editor
          //toolbarOnFocus
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
            image: {
              uploadCallback: uploadImageCallBack,
              alt: { present: true, mandatory: true },
            },
          }}
        />
        <Row>
          <FormGroup style={{ marginTop: 40, marginLeft: 15 }}>
            <Button
              color="primary"
              onClick={save}
              disabled={loading}
            >
              {loading ? "loading" : "Хадгалах"}
            </Button>
          </FormGroup>
        </Row>
      </div>
    );
  
}

export default EditorContainer;
